<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item label="机构名称：">
					<el-autocomplete
						v-model.trim="formInline.name"
						:fetch-suggestions="querySearchAsync"
						clearable
						placeholder="请输入内容"
						@select="handleSelect"
					></el-autocomplete>
				</el-form-item>
				<el-form-item label="省：">
					<el-select v-model="formInline.econCode" placeholder="请选择省" @change="changeEconomize">
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：">
					<el-select v-model="formInline.cityCode" placeholder="请选择市">
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="等级：">
					<el-select v-model="formInline.levelDictionaryItemId" placeholder="请选择" clearable>
						<el-option v-for="(item, index) in levelDictionaryItemList" :key="index" :label="item.title" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
				</template>
				<template #departmentConfig="{ scope }">
					<el-button @click="departmentConfig(scope.row)" size="mini" type="text"> 科室配置 </el-button>
				</template>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button
						@click="modifyParkInfo($event, false, scope.row)"
						size="mini"
						:type="scope.row.enabled ? 'info' : 'warning'"
						:disabled="scope.row.enabled"
					>
						修改
					</el-button>
				</template>
			</Table>
		</div>
		<createView ref="createView" :isShowDetail="isShowDetail" @getList="getList" />
	</div>
</template>

<script>
export default {
	name: 'hospitalInfo',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
	},
	data() {
		return {
			formInline: {
				name: '',
				econCode: null,
				cityCode: null,
				levelDictionaryItemId: null,
			},
			levelDictionaryItemList: [], //机构等级
			economizeList: [],
			marketList: [],
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '医疗机构代码',
					prop: 'organizationCode',
					width: 110,
					formatter: (row) => {
						return row.organizationCode || '-';
					},
				},
				{
					label: '医疗机构名称',
					prop: 'name',
					width: 110,
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '所属地市',
					prop: 'regions',
					formatter: (row) => {
						return row.regions && row.regions.length > 0 ? row.regions[1].title : '-';
					},
				},
				{
					label: '等级',
					prop: 'levelDictionaryItem',
					formatter: (row) => {
						return row.levelDictionaryItem?.title || '-';
					},
				},
				{
					label: '所有制形式',
					width: 100,
					prop: 'ownershipDictionaryItem',
					formatter: (row) => {
						return row.ownershipDictionaryItem?.title || '-';
					},
				},
				{
					label: '性质',
					prop: 'characteristic',
					formatter: (row) => {
						return row.characteristic || '-';
					},
				},
				{
					label: '联系人',
					prop: 'principalName',
					formatter: (row) => {
						return row.principalName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '科室管理',
					prop: 'departmentConfig',
				},
				{
					label: '是否启用',
					prop: 'enabled',
					width: 110,
				},
			],
			isShowDetail: false,
			filterLoading: false,
			options: [],
			link: '',
		};
	},
	props: {},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getEconomizeList();
		this.getList();
		this.getInstitList();
	},
	methods: {
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		changeEconomize(data) {
			if (!data || data == '') return;
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code: data, size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.marketList = res.data.collection || [];
						this.marketList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		handleSelect(subInput) {
			this.formInline.name = subInput.name;
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		changeOpen(row) {
			this.$http
				.patch(row._links.patch.href, { enabled: row.enabled })
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		// 获取机构等级list
		getInstitList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'INSTITUTIONAL_LEVEL' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.levelDictionaryItemList = res.data.collection || [];
						this.levelDictionaryItemList.unshift({
							id: null,
							title: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		//获取列表
		getList(filterType = '') {
			filterType == 'filter' ? (this.filterLoading = true) : (this.loading = true);
			let data = {
				...this.formInline,
				...this.queryData,
				discriminationCode: 'O',
				regionCode: this.formInline.econCode && !this.formInline.cityCode ? this.formInline.econCode : this.formInline.cityCode,
			};
			this.$http
				.get(this.api['MedicalOrganizations#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (filterType == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.name,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
							this.link = res.data._links.create.href || '';
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			let data = {
				row: row || null,
				link: row ? row._links.update.href : this.link,
			};
			this.$refs.createView.init(data);
			this.isShowDetail = isShowDetail;
		},
		departmentConfig(row) {
			this.$router.push({
				path: '/institutionManager/departmentManager',
				query: {
					id: row.id,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}

.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
.seeMore {
	color: #0d99ff;
}
</style>
